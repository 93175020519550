@font-face {
  font-family: "SunovaFontSemiBold";
  src: local("MyriadProSemibold"),
    url("./font/MyriadProSemibold.OTF") format("truetype");
}
@font-face {
  font-family: "SunovaFontRegular";
  src: local("MyriadProRegular"),
    url("./font/MyriadProRegular.OTF") format("truetype");
}

* {
  scroll-behavior: smooth;
  font-family: "SunovaFontSemiBold", sans-serif !important;
}

*::-webkit-scrollbar {
  /* display: none; */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table,
th,
td {
  text-align: center;
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
}

.button {
  margin-block: 5px;
}
